@import "Utils/_colors.scss";

.project-side-panel {
  width: 15rem;
  position: fixed;
  top: 3.6rem;
  right: 0;
  background-color: #fff;
  height: calc(100% - 6rem);
  border-left: 1px solid $default-border-color;
  padding: 1.2rem;
  transform: translateX(100%);
  transition: transform 0.4s ease;
  z-index: 5;
  &.slided {
    transform: translateX(0);
  }
  .performance-log-toggle {
    position: absolute;
    right: 100%;
    top: 0.8rem;
    background-color: $default-border-color;
    padding: 0.5rem;
    white-space: nowrap;
    border: none;
    border-radius: 0.3rem 0 0 0.3rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 0.7rem;
  }
  .app-map-wrapper {
    width: auto;
    height: 15.7rem;
    border: 1px solid $default-border-color;
    border-radius: 0.2rem;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    .app-map {
      width: 96% !important;
      height: 96% !important;
      margin: 0.26rem;
    }
  }

  .messages-list {
    font-size: 0.7rem;
    margin: 2rem 0;
    max-height: 15rem;
    overflow-y: auto;
    text-align: left;
    .message-item {
      color: #0c64c0;
      margin-bottom: 0.5rem;
      &.error {
        color: #ed5c57;
      }
      .message-time {
        color: initial;
      }
      .drawer , .reciever {
        display: inline-block;
        margin-right: 0.3rem;
      }
      .message-description {
        font-size: 0.6rem;
        font-weight: bold;
      }
      .selected-device {
        margin-right: 0.3rem;
      }
    }
  }

  .messages-search-wrapper {
    margin-top: 1rem;
    input {
      width: 100%;
      height: 2rem;
      padding: 0 1rem;
      box-sizing: border-box;
    }
  }
}
