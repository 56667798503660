@import "Utils/colors";

.create-bookmark {
  flex: 1 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10;
  position: absolute;
  bottom: 12%;

  h4,
  h3 {
    text-align: center;
  }

  div.BM_Modal {
    width: 460px;
    min-height: 200px;
    margin: 0px auto;
    border: 1px solid #808080;
    box-shadow: 2px 2px 5px 4px rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    background-color: rgba(230, 230, 230, 0.9);
    position: relative;
    color: $default-black;

    form {
      svg.close {
        display: flex;
        position: absolute;
        right: -1px;
        top: -2px;
        font-size: 2rem;
        color: $default-black;
        margin: 0;
        padding: 0;
      }

      .form-info {
        display: inline-block;
        min-width: 100%;

        & > div {
          display: flex;
          justify-content: space-between;
          margin: 2%;
          min-width: 20%;
          flex-direction: column;
        }

        .label {
          font-weight: 500;
        }

        .checkbox-final-form-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin: 0;

          .checkbox-final-form {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 0;

            input {
              margin-right: 5px;
            }
          }
        }

        div.bookmarkTextaria {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .bookmarkText {
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
            background-color: #fcfdfd;
            overflow: auto !important;
            font-size: 15px;
            text-align: center;
            border: none;
            width: 80%;

            &:focus {
              outline: none;
              border-bottom: transparent;
              border-top: transparent;
              border-left: transparent;
              border-right: transparent;
              font-size: 16px;
            }
          }
        }

        input {
          padding: 3px 5px;
          font-size: 1em;
          margin: 5px auto 0;
          border-top: transparent;
          border-left: transparent;
          border-right: transparent;
          background-color: transparent;
          resize: vertical;
          text-align: center;

          #file-upload-button {
            background-color: transparent;
          }
        }

        input:focus {
          outline: none;
          border-bottom: 2px solid $default-black;
          border-top: transparent;
          border-left: transparent;
          border-right: transparent;
          font-size: 18px;
        }

        span {
          margin: 0 auto 5px;
          color: #800;
          font-weight: bold;
        }

        input[type="file"] {
          appearance: none;
          background-color: initial;
          cursor: default;
          color: inherit;
          text-overflow: ellipsis;
          white-space: pre;
          text-align: center;
          padding: initial;
          border: initial;
          overflow: hidden !important;
          text-align-last: end;
        }

        .header {
          font-size: 20px;
          text-align: center;
          margin: 5px auto;
          padding-top: 5px;
          font-weight: 600;
        }
        .content {
          padding: 10px 5px;
          text-align: center;
          font-size: 18px;
          margin: 0px 5px;
          font-weight: 600;
        }
        .actions {
          width: 50%;
          padding: 10px 5px;
          margin: auto;
          text-align: center;
        }

        .buttons {
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          margin-top: 15px;
        }

        button {
          margin: 0 10px;
        }

        .btn {
          white-space: nowrap;
          display: inline-block;
          border-radius: 5px;
          padding: 5px 15px;
          font-size: 16px;
          color: $default-black;

          &:visited {
            color: $default-white;
          }

          &:visited {
            color: $default-black;
          }

          &[disabled] {
            opacity: 0.6;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
