@import "../../../Mixins/scrollbar.scss";

.analytics-data-display-wrapper {
  width: 100%;
  height: 100%;
  text-align: left;
  overflow: auto;
  overflow-x: hidden;
  @include custom-scrollbar;
}
