@import "Utils/colors";

.create-new-project-wrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;

  .add {
    position: relative;
    margin: 1rem 0;
    cursor: pointer;
    width: 30px;
    height: 30px;

    &:hover {
      color: #5f9ea0;
    }
  }
}

.modal {
  form {
    position: relative;
    height: 100%;

    .close-icon {
      display: flex;

      svg.close {
        position: absolute;
        right: -1px;
        top: -0.15rem;
        font-size: 2rem;
        color: $default-black;
        margin: 0;
        padding: 0;
      }
    }

    .form-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      min-width: 100%;

      .checkbox-final-form {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .label {
          flex: 0.6;
        }

        input {
          margin-left: 0.7rem;
          margin-top: 0.1rem;
          flex: 0.2;
        }
      }

      .projectName,
      .proCode,
      .timezone,
      .projectImage {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 3%;
        min-width: 20%;
        flex-direction: column;
      }

      .label {
        font-weight: 500;
        text-align: center;
      }

      input {
        display: flex;
        text-align: center;
        padding: 0.15rem 0.26rem;
        font-size: 1em;
        margin: 0.26rem auto 0;
        border-top: transparent;
        border-left: transparent;
        border-right: transparent;
        background-color: transparent;
        resize: vertical;
        text-align: center;
      }

      input:focus {
        outline: none;
        border-bottom: 2px solid $default-black;
        border-top: transparent;
        border-left: transparent;
        border-right: transparent;
        font-size: 0.94rem;
      }

      .select-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0 0.5rem;

        .label {
          margin: 0.1rem auto;
        }
      }

      span {
        margin: 0 auto 0.26rem;
        color: #800;
        font-weight: bold;
      }

      .buttons {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin: 0.78rem;

        button {
          margin: 0 0.5rem;
        }

        .btn {
          white-space: nowrap;
          display: inline-block;
          border: 1px solid $default-black;
          border-radius: 0.26rem;
          padding: 0.26rem 0.78rem;
          font-size: 0.84rem;
          color: $default-black;
          cursor: pointer;

          &:hover {
            color: $default-red;
            border: 2px solid $default-red;
            font-size: 0.84rem;
          }

          &[disabled] {
            opacity: 0.6;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
