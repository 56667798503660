@import "Utils/colors";

.project-card:hover {
  background-color: $default-hover-color;
  border-radius: 0.5rem;
  cursor: pointer;
}
.project-card-wrapper {
  flex: 0 1 22%;
  margin: 1rem;
  position: relative;
  .project-card-buttons-wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    vertical-align: middle;
    position: absolute;
    z-index: 2;
    right: 0.2rem;
    top: 0.55rem;
    cursor: pointer;
  }
}
.project-card {
  color: #282a36; //todo: change
  background: #fff; //todo: change
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  border-radius: 0.5rem;
  border: 0.15rem solid #eee;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  .upper-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
    align-items: center;
    padding: 0.5rem 0.78rem;
    border-bottom: 1px solid #eee;
    flex: 0 1 2rem;
    .image-wrapper {
      max-width: 2rem;
      img {
        width: 100%;
        height: auto;
      }
    }
    .project-name {
      padding: 0 0.78rem 0.26rem;
      font-weight: bold;
      font-size: 1rem;
      a {
        color: #282a36;
        text-decoration: none;
      }
    }
    .activity-indicator {
      width: 1.57rem;
      height: 1.57rem;
      border-radius: 0.5rem;
      background-color: #d7260e;
      &.active {
        background-color: #61ef61;
      }
      &.blink {
        animation: blinker 5s linear infinite;
      }

      @keyframes blinker {
        50% {
          opacity: 0;
        }
      }
    }
  }

  .main-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 0 1 7rem;

    .mus-vus-middle-border {
      width: 1px;
      background-color: #eee;
    }
  }

  .image-wrapper {
    position: relative;
  }
}
