@import url("https://fonts.googleapis.com/css2?family=Urbanist&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Readex+Pro&display=swap");

$default-hover-color: #ccddff;
$default-selected-color: rgba(204, 221, 255, 0.5);
$default-OFF-button-color: #ffffff;
$default-OFF-hover-color: rgba(204, 221, 255, 0.7);
$default-button-color: #ffffff;
$default-border-color: #ccddff;
$default-text-color: #001133;
$white-text-color: #fafbfb;
$abort-text-color: #ffffff;
$abort-button-color: #001133;
$OFF-color: #ccddff;
$ON-color: #00ffff;
$contactor-on-color: #f81728;
$idle-color: #ccffff;
$forced-color: #ffd600;
$active-color: #00ffff;
$default-tab-text-color: #80aaff;
$default-subtitle-color: #ccddff;
$side-navigation-background-color: #f5f8ff;
$default-gray: #aaaaaa;
$default-light-white: #eeeeee;
$default-white: #ffffff;
$default-black: #000000;
$default-red: #ff0000;
$login-bg-gradient: #f6e58d;
$switch-btn-bg: #002266f0;
$default-light-blue: #026fc0;
$default-green: #008000;
$primary-font-family: "Urbanist", Arial;
$default-header-text-color: #ffffff;
$branding-gray: #62696f;
$branding-dark-gray: #23292e;
$analytics-top-menu: #33373a;
$summary-table-panel-color: #F5F5F5;

:export {
  idleColor: $idle-color;
}
