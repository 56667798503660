@import "Utils/_colors.scss";

.select-wrapper {
  min-width: fit-content;
  width: 7.36rem;
  margin: 0.26rem;
  height: fit-content;
}

.select-button {
  position: relative;
  > div:first-of-type {
    height: 1.7rem;
    border-color: $default-border-color;
    border-radius: 0.2rem;
    min-height: 0;
    display: flex;
    align-items: center;
    > div {
      padding: 0 0.2rem !important;
      height: 100%;
      line-height: 1;
      span {
        margin: 0;
        height: 100%;
      }
    }
    svg {
      width: 1rem;
    }
    div[aria-hidden="true"] {
      padding: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.2rem 0 0.2rem 0.2rem;
    }
  }
}
