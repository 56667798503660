@import "Utils/colors";

.contactors-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .switch-button {
    width: 8rem;
  }

  .abort-button-wrapper {
    border-radius: 0.2rem;
    border: 1px solid $default-border-color;
    width: 8rem;

    .indication-button-wrapper {
      height: 100%;
    }

    .abort-contactor {
      width: 100% !important;
      height: 1.7rem !important;
      margin: 0;
      background-color: $abort-button-color;
      color: $abort-text-color;
      border: 2px solid $default-white;
      border-radius: 4px;
      padding: 0;

      .indication-button-text {
        padding: 0 !important;
        white-space: nowrap;
      }
    }
  }
}
