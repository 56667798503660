@import "Utils/_colors";

.app-button {
  border-radius: 0.2rem;
  border: 1px solid $default-border-color;
  font-size: 0.73rem;
  font-family: Inter;
  margin-right: 2px;
  margin-left: 2px;
  background-color: $default-button-color;
  color: $default-text-color;
  font-style: normal;
  font-weight: 400;
  padding: 0.5rem 1.47rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 5px;
  }

  &.disabled {
    border-color: $default-light-white;
    color: $default-gray;
    opacity: 50%;
  }
}

.app-button:hover {
  background-color: $default-hover-color;
}
