@import "Utils/colors";

.fans-view {
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  margin-right: 0.26rem;
  gap: 1.1rem;
  height: 100%;
  padding: 1rem 0;

  .segment-row-double-fans {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .indication-button {
      margin-bottom: 0.36rem;
      img {
        max-width: 0.7rem;
      }
    }

    .two-fans-status {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.4rem;
    }

    .segment-row-fan {
      border-radius: 50%;
      width: 1.26rem;

      img {
        width: 100%;
        display: block;
        &:hover {
          fill: #f1f0f0;
        }
      }
    }
  }
}

.back-fan {
  width: fit-content;
  top: 100%;

  .segment-row-back-fan-force-all {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .fan-force-all,
    .segment-row-back-fan {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .image-wrapper {
        width: 1.26rem;

        img {
          width: 100%;
        }
      }

      label {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        flex: 1;
        font-size: 0.73rem;
        width: fit-content;
        font-weight: 400;
        color: $default-text-color;
      }
    }
  }
}
