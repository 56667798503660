@import "Utils/colors";

.depot-aquarium-section-wrapper {
  width: 135px;
  padding: 1rem 0;
  height: 44rem;
  background-color: $default-light-white;
  border-radius: 5px;
  box-sizing: border-box;

  .charging-icons-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
  
    .charging-icon {
      position: absolute;
      transform: translateY(50%);
      display: block;
      width: 1rem;
      left: 0;
      margin: 0;
    }
  }
}
