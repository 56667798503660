@import "Utils/_colors.scss";

.rbc-event {
  background: #04cabf;
  color: $default-black;
  height: 100%;
}

.rbc-overlay {
  transform: translate(900px, 400px) !important;
}

.rbc-show-more {
  color: #04b2d5;
  background-color: transparent;
}

span {
  text-align: center;
}

.popup-content {
  margin: auto;
  width: 50%;
  padding: 5px;
}

.popup-arrow {
  color: $default-white;
}

[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.7);
}

[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

.modal {
  width: 460px;
  min-height: 200px;
  margin: 0px auto;
  border: 1px solid #808080;
  box-shadow: 2px 2px 5px 4px rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  background-color: #e6e6e6;
  position: relative;
  color: $default-black;

  h4,
  h3 {
    text-align: center;
  }

  .the_bookmark {
    flex-direction: column;
    width: 80%;
    margin: 10px auto;
    padding: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    background-color: #fcfdfd;

    .header {
      font-size: 20px;
      text-align: center;
      margin: 0px 5px;
      padding-top: 5px;
      font-weight: 600;
    }

    .content {
      display: flex;
      justify-content: space-around;
      text-align: center;
      align-items: center;
      padding: 10px 5px;
      text-align: center;
      font-size: 18px;
      margin: 0px 5px;
      font-weight: 400;
      overflow: auto !important;
    }

    .actions {
      width: 50%;
      padding: 10px 5px;
      margin: auto;
      text-align: center;
    }

    .close {
      position: absolute;
      right: -1px;
      top: -2px;
      font-size: 1.5rem;
      color: $default-black;
      margin: 0;
      padding: 0;
    }

    & > .actions {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      margin-top: 15px;
    }

    button {
      margin: 0 10px;
    }

    .btn {
      white-space: nowrap;
      display: inline-block;
      border-radius: 5px;
      padding: 5px 15px;
      font-size: 16px;
      color: $default-black;

      &:visited {
        color: $default-white;
      }

      &:visited {
        color: $default-black;
      }
      &[disabled] {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }
}

.event-wrapper {
  display: "flex";
  justify-content: "space-between";
}

.calendar-container {
  height: "90%";
  width: "100%";
}
