@import "Utils/_colors.scss";

.indication-button {
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;
  font-family: Inter;
  font-weight: 400;
  border: 1px solid $default-border-color;
  font-size: 0.73rem;
  margin-right: 0.1rem;
  margin-left: 0.1rem;
  background-color: $default-white;
  width: auto !important;
  height: 1.9rem !important;
  min-width: 1.9rem;
  padding: 0 0.2rem;
  display: flex;
  align-items: center;
  justify-self: unset;

  img {
    max-width: 1rem;
  }

  &:hover {
    background-color: $default-hover-color;
    cursor: pointer;
  }

  &.force-off-button {
    background-color: $default-OFF-button-color;

    &:hover {
      background-color: $default-OFF-hover-color;
    }
  }

  &.disabled {
    border-color: $default-light-white;
    color: $default-gray;

    img {
      opacity: 0.5;
    }
  }
}
