.menu-wrapper {
  display: flex;

  .menu-button {
    cursor: pointer;
  }

  .menu-item {
    display: flex;
    cursor: pointer;
    padding-right: 20px;
    height: 3.6rem;

    .item {
      display: flex;
      align-items: center;
    }

    .selected {
      color: #66c2d5;
      border-bottom: 3px solid #66c2d5;
    }

    .item-img {
      margin-right: 5px;
    }
  }
}
