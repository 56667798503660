.discrete-graph .recharts-layer.recharts-line {
  stroke-linecap: square;
}

.discrete-graph {
  overflow: hidden;
  width: 100%;
}

.line-graph-wraper {
  width: 100%;
  overflow: hidden;
  padding-top: 25px;

  .recharts-surface {
    overflow: visible;
  }
}

.activity-graph-container {
  border-block: 1px solid #d9dbde;
}