@import "Utils/_colors.scss";

.data-summary {
  padding: 0 0.5rem;
  color: $default-text-color;
  font-size: 0.63rem;
  font-weight: 400;
  border-left: solid 1px $default-border-color;
  text-align: left;
  flex: 1;

  > div {
    display: flex;
  }

  .value {
    font-size: 0.94rem;
    font-weight: 600;
    margin-right: 0.2rem;
  }

  .C-units {
    font-size: 0.84rem;
  }

  .subtitle {
    color: $default-subtitle-color;
    font-size: 0.64rem !important;
    white-space: nowrap;
  }

  &.no-left-border {
    border-left: none;
    margin-right: 0px;
    margin-left: 0.5rem;
  }
}
