@import "Utils/colors";

.selected-segment-summary {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: $default-white;

  .selected-segment-name {
    font-size: 0.73rem;
    font-weight: 400;
    color: $default-text-color;
    min-width: fit-content;
    padding-right: 1.26rem;
  }

  .selected-drawer-segment-duty-cycle-value {
    max-width: 1.9rem;
    font-size: 0.94rem;
    font-weight: 600;
    font-style: normal;
    margin-left: 1.26rem;

    span {
      font-size: 0.6rem;
      font-weight: normal;
      margin-left: 0.2rem;
    }
  }

  .first-row {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 0.7rem;

    .selected-segment {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.1rem;
      width: 2.8rem;
    }
  }

  .second-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 0.5rem;
    align-items: flex-start;

    .selected-segment {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-left: 0.1rem;

      .selected-segment-value {
        margin-left: 0.26rem;
      }
    }

    .duty-cycle-percentage-bar-wrapper,
    .RF-percentage-bar-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .segment-bars-wrapper {
      border-left: solid 1px $default-border-color;
      padding-left: 1.26rem;
      padding-right: 1.26rem;
      display: flex;
      gap: 1.05rem;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.84rem;
      justify-content: space-between;

      .subtitle {
        font-size: 0.64rem !important;
      }

      .graph-segment-duty-cycle-slider {
        width: 3.6rem;
        margin-right: 0.2rem;
        margin-left: 0.2rem;
      }

      .duty-cycle-percentage-bar,
      .RF-percentage-bar {
        display: flex;
        flex-direction: row;
        align-items: center;

        .graph-segment-duty-cycle-value,
        .graph-segment-RF-value {
          font-size: 0.94rem;
          font-weight: 600;
          min-width: 41px;
          text-align: left;

          span {
            font-size: 0.6rem;
            font-weight: 400;
          }
        }

        .force-rf-icon,
        .duty-cycle-icon {
          margin-right: 0.2rem;

          img {
            max-height: 0.8rem;
          }
        }
      }

      .subtitle {
        color: $default-subtitle-color;
        padding-left: 0.26rem;
      }
    }
  }
}
