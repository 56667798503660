@import "Utils/colors";

.mini-vu-wrapper {
  padding: 0.5rem 0.8rem;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: stretch;
  .add:hover {
    cursor: pointer;
  }
  .vu-cellular-icon {
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 7px;
  }

  modal {
    .close-icon {
      display: flex;

      svg.close {
        position: absolute;
        right: -1px;
        top: -3px;
        font-size: 2rem;
        color: black;
        margin: 0;
        padding: 0;
      }
    }
  }

  .add-vehicle-form {
    .form-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .checkbox-final-form {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .label {
          flex: 0.6;
        }
        input {
          flex: 0.2;
        }
      }
    }
  }

  .vu-basic-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    img {
      max-height: 100%;
    }
    .add {
      &.disabled {
        opacity: 0.5 !important;
        cursor: not-allowed !important;
        pointer-events: all;
      }
    }
    .vu-name:hover {
      cursor: pointer;
    }
    .mini-vu-header-icons {
      display: flex;
      flex-direction: row;
      gap: 0.4rem;
      flex: 0 1 25%;
      justify-content: flex-start;
      position: relative;
      &:last-of-type {
        justify-content: flex-end;
      }
      .bus-icon {
        padding-right: 1rem;
        img {
          width: 3rem;
        }
      }
      .charging-status {
        position: absolute;
        right: 0;
        img {
          display: block;
          border-radius: 50%;
          padding: 0.15rem;
          width: 1rem;
          height: 1rem;
        }
      }
    }
    .vu-status-indication {
      width: 5.2rem;
      height: 1.26rem;
      border-radius: 1.57rem;
      padding-right: 0.2rem;
      padding-left: 0.2rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 0.63rem;
      gap: 0.2rem;
    }
    .select-wrapper {
      font-size: 0.7rem;
    }
  }

  .vu-data-summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    justify-content: center;

    .vu-data-summary-item {
      display: flex;
      flex-direction: row;
    }
  }

  .vu-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    table {
      width: 100%;
    }
    .receiver-status {
      display: flex;
      flex-direction: column;
      align-items: center;
      .RF-icon {
        justify-content: center;
        border-radius: 0.84rem;
        margin-top: 0.4rem;
        width: 1.26rem;
        height: 1.26rem;
        background-color: $ON-color;
      }
    }
  }
  .locked-unlock-icon {
    margin-left: 0;
  }
}
