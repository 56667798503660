@import "Utils/_colors.scss";

.rack-config-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .appButton-progress {
    position: relative;
    .progress-line {
      height: 3px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 50%;
      background-color: $default-tab-text-color;
      transition: width 0.4s ease;
    }
  }

  .config-buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .app-button {
    height: fit-content;
    margin: 0;
  }
}
