@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400&display=swap");

html,
body,
.App,
#root {
  height: 100%;
  background-color: #f7f8ff;
}

html {
  font-size: 1vw;
}
body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.App {
  text-align: center;
  height: 100%;
  width: 100%;
  background-color: #f7f8ff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .home-route {
    width: 100%;
  }
}

.general-invisible {
  visibility: hidden;
}

.general-no-display {
  display: none;
}

.generate-button {
  height: 45px;
  width: 91px;
  background-color: #48ff91;
  border: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
  cursor: not-allowed;
}

.disabled-button {
  //border: 1px solid #999999;
  color: #666666;
  opacity: 0.7;
}

button {
  background: #ededed;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
}

button:active {
  background: #e5e5e5;
  -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
  -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
  box-shadow: inset 0px 0px 5px #c1c1c1;
  outline: none;
}

.horizontal-flipped-img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.gray-layout {
  opacity: 50%;
}
