@import "Utils/colors";

.dcSampler-data-summary {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: $default-text-color;
  font-weight: bold;

  .dcSampler-data-summary-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width: 4.2rem;

    .dcSampler-data-summary-item-value {
      font-size: 0.9rem;
    }

    .dcSampler-data-summary-item-unit {
      font-size: 0.6rem;
    }
  }
}
