@import "Utils/colors";

.project-device-graph-wrapper {
  display: flex;
  flex-direction: row;
  height: auto;
  padding: 0.7rem;

  .graph-performance-log {
    width: 17%;
    height: 100%;
    border-left: 1px solid $default-border-color;
    position: fixed;
    top: 4.2rem;
    right: 0;
    background-color: $default-white;
    padding-top: 0.9rem;
  }

  .project-device-graph {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;

    .project-device-header-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0.5rem;
    }

    .project-view-main-graph-drawers-wrapper {
      width: 100%;

      .project-view-main-graph-drawers {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .project-view-main-performance-log {
        width: 15rem;
        position: fixed;
        top: 3.6rem;
        right: 0;
        background-color: $default-white;
        height: calc(100% - 6rem);
        border-left: 1px solid $default-border-color;
        padding: 1.2rem;
        transform: translateX(100%);
        transition: transform 0.4s ease;
        z-index: 5;

        &.slided {
          transform: translateX(0);
        }

        .performance-log-toggle {
          position: absolute;
          right: 100%;
          top: 0.8rem;
          background-color: $default-border-color;
          padding: 0.5rem;
          white-space: nowrap;
          border: none;
          border-radius: 0.3rem 0 0 0.3rem;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          font-size: 0.7rem;
        }

        .app-map-wrapper {
          width: auto;
          height: 15.7rem;
          border: 1px solid $default-border-color;
          border-radius: 0.2rem;
          position: absolute;
          bottom: 1rem;
          left: 1rem;
          right: 1rem;

          .app-map {
            width: 96% !important;
            height: 96% !important;
            margin: 0.26rem;
          }
        }
      }
    }

    .project-view-main-graph {
      width: 100%;
      height: 100%;
      border: 1px solid $default-border-color;
      border-radius: 0.2rem;
      margin-bottom: 0.84rem;

      &.closed {
        height: 10%;

        .recharts-surface {
          height: 10%;
        }
      }

      .graph-view {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0.6rem;
        position: relative !important;

        .graph-options {
          display: flex;
          flex: 1;
          font-size: 0.7rem;
          flex-direction: row;
          justify-content: flex-start;
          flex: 0 0 8%;

          .live-graph-options-wrapper,
          .history-graph-options-wrapper {
            margin-left: 1rem;
            padding-left: 1rem;
            border-left: $default-border-color solid;
            display: flex;
            align-items: center;
          }

          .history-graph-options-wrapper {
            .load-history-btn,
            .export-button {
              height: 1.9rem;
              margin-left: 1rem;
              width: 7.5rem;
            }

            .export-raw-data-button {
              width: fit-content;
            }
          }

          .switch-button.history-live-switch-btn {
            margin: 0.26rem;

            &:before {
              content: "History";
            }

            &.checked {
              .switch-button-label-span {
                color: $default-text-color;
              }

              &:before {
                background-color: $switch-btn-bg;
                height: 1.35rem;
              }
            }

            &.unchecked {
              .switch-button-label-span {
                color: $default-button-color;
              }
            }
          }

          .switch-button.history-live-switch-btn.unchecked
            .switch-button-label:before {
            background-color: $switch-btn-bg;
          }

          .freeze-button {
            height: 1.7rem;
            width: 6rem;
            margin: 0.26rem;
            font-size: 0.7rem;
            padding: 0;
          }

          .frozen {
            background-color: $switch-btn-bg;
            color: $default-white;
          }
        }

        .recharts-responsive-container {
          flex: auto;
          position: relative !important;
          height: 10rem !important;
        }
      }

      .graph-radio-buttons-wrapper {
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
        padding-left: 0.26rem;
        padding-right: 0.26rem;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .graph-radio-buttons {
          display: flex;
          flex-direction: row;
          justify-content: center;

          .graph-radio-button {
            padding: 0.26rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 3.84rem;
          }
        }
      }

      .project-graph {
        &.closed {
          display: none;
        }

        &.open {
          display: block;
        }
      }

      .graph.arrow-collapse {
        z-index: 2;

        button {
          font-size: larger;
        }
      }

      .recharts-wrapper {
        svg {
          width: 100% !important;
          margin-top: 0.4rem;

          text {
            font-size: 0.6rem;
          }
        }
      }
    }

    .project-view-drawers-map {
      width: 100%;

      .vu-performance {
        padding: 0.9rem;
        border-radius: 0.2rem;
        border: 1px solid $default-border-color;
        flex: none;
        width: 68%;
        margin-right: 0.84rem;

        .vu-performance-control-receivers {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          flex: 1;
        }

        .receiver-view {
          width: 100%;

          table {
            width: 100%;
          }

          tr {
            td {
              .image-wrapper {
                img {
                  width: 0.9rem;
                }
              }

              .activity-indicator-wrapper {
                margin: 0;
              }
            }
          }
        }
      }

      .mu-performance {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-height: 26.5rem;

        .drawers-contactors-buttons-wrapper {
          flex: 0 1 32%;
          margin-right: 1rem;
          margin-bottom: 0;

          //.mu-leds-indication {
          //  flex: 0 1 100%;
          //}
        }

        .project-graph-selected-drawer-segments {
          flex: 0 1 40%;
          padding: 0.2rem 0.8rem;
          overflow-x: auto;
        }
      }

      .vu-performance {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }

      .graph-vehicle-info-map {
        flex: 0 1 27%;
        display: flex;
        flex-direction: column;

        .graph-vehicle-info {
          flex: 0 1 40%;
          margin-bottom: 1rem;
          border: 1px solid $default-border-color;
          border-radius: 0.2rem;
        }

        .app-map-wrapper {
          padding: 0.6rem;
          flex: 1 1 60%;
          border: 1px solid $default-border-color;
          border-radius: 0.2rem;

          .app-map {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }

      .contactor-on {
        border: 2px solid $contactor-on-color;
      }
    }
  }
}
.zoom-info-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 0;
  padding-bottom: 0;

  .zoom-info {
    margin-bottom: 0;
    margin-top: 1%;
    display: flex;
    width: 85%;
    align-items: center;
    justify-content: center;

    .zoom_loader {
      margin-left: 1rem;
    }
  }
}
.zoom-reset-btn {
  margin-right: 1%;
}
.graph-vehicle-info {
  padding: 0.8rem;
  border-radius: 0.2rem;
  border: 1px solid $default-border-color;
  flex: 0 1 26%;
}
