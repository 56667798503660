@import "Utils/_colors";

.app-header {
  height: 3.6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $branding-dark-gray;
  position: relative;
  font-family: $primary-font-family !important;
  color: $default-header-text-color;
  font-weight: 400;
  font-size: 0.95rem;
  padding-left: 1.6rem;
  z-index: 10;
  backdrop-filter: blur(50px);

  &.transparent-background {
    background-color: transparent;
  }

  .electreon-main-logo:hover {
    cursor: pointer;
  }

  .logout {
    border-radius: 0.2rem;
    border: 1px solid $default-border-color;
    background-color: $default-white;
    min-width: 1.9rem;
    padding: 0.47rem;

    img {
      max-width: 1rem;
    }

    &:hover {
      background-color: $default-hover-color;
      cursor: pointer;
    }
  }

  .header-tooltip-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: middle;
    position: relative !important;
    border-right: none !important;

    .tooltip-user-data {
      margin-left: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .__react_component_tooltip::before,
  .__react_component_tooltip::after {
    content: none !important;
  }

  .app-header-left {
    display: flex;
    align-items: center;

    .project-header-logo {
      margin-left: 1rem;
    }
  }

  .app-header-right {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .breadcrumb {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    vertical-align: middle;
    font-weight: 400;
  }

  .header-date-time {
    margin-right: 1rem;
    display: flex;
    font-size: 1rem;
    gap: 0.4rem;
  }

  .menu-icon {
    position: absolute;
    left: 0.4rem;
  }

  .navigation-indication {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: auto;
    align-items: center;
    margin-left: 15px;
  }

  .live-history-toggle-button {
    flex: 1;
  }

  .search-header {
    display: flex;
    flex-direction: column;
  }

  .yoda-tip {
    margin-left: 3rem !important;
    padding-top: 0.5rem !important;
    top: 2.8rem !important;
  }

  .notification-bell-icon-wrapper {
    margin-right: 1rem;

    .image-wrapper {
      vertical-align: middle;
    }
  }
  .user-icon-letters {
    font-size: 0.8rem;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    background-color: $default-light-blue;
    line-height: 2.3;
    text-align: center;
    vertical-align: middle;
  }

  .user-icon-wrapper {
    margin-left: 10px;
  }

  .arrow-icon-wrapper {
    height: 1.2rem;
    padding: 0;

    .image-wrapper {
      height: 100%;
    }
  }
}
