@import "Utils/colors";

.loading {
  svg {
    width: 20px;
    height: 20px;
  }
}

.info {
  margin-left: 10px;
  width: 100px;
  border: 1px solid $default-black;
  border-radius: 10px;
  padding: 5px;
}

.links {
  display: flex;
  align-items: flex-start;
  width: 100px;
  justify-content: space-around;
  flex-wrap: wrap;
}
