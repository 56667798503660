@import "Utils/_colors.scss";

.app-number-input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  .app-number-input-value-unit {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    [readOnly] {
      border: none;
    }
  }
  .app-number-input-buttons {
    display: flex;
    flex-direction: row;
  }
  .app-number-input-content {
    display: flex;
    flex-direction: row;
  }
  .buttonsOnTheRight {
    flex-direction: row-reverse;
  }
  .app-number-input {
    border: 1px solid $default-border-color;
    box-sizing: border-box;
    border-radius: 4px;
    height: 1.9rem;
    width: 4.2rem;
    background: $default-button-color;
    padding: 0.5rem;
    font-size: 0.7rem;
  }
  .is-updating {
    background: $default-selected-color;
    color: #0000ff;
  }
  .error,
  .error:focus-visible {
    border: 1px solid $default-red;
    outline: none;
  }
  label {
    margin-right: 0.42rem;
  }
  .unit-suffix {
    position: absolute;
    right: 0.42rem;
    font-size: 0.5rem;
    color: #026fc0 !important;
  }
}

//Hide the up&down arrows from the input (type = number)

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="text"] {
  -moz-appearance: textfield;
}
