@import "Utils/colors";

.project-graph-selected-drawer-segments {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 33%;
  margin-right: 1rem;
  border: 1px solid $default-border-color;
  border-radius: 0.2rem;
  align-items: center;
  padding-left: 0.26rem;

  .graph-selected-drawer-segment {
    border-bottom: 1px solid $default-border-color;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.6rem 0;
    align-items: flex-start;
  }

  .graph-selected-drawer-segment-status-and-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .forces-buttons-contactor-warning {
      margin: 0;
    }
    .off-and-idle-buttons {
      min-width: fit-content;
      margin-right: 5px;
      margin-left: 5px;
      display: flex;
    }
  }

  .segment-operational-mode-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0.26rem;

    justify-content: space-between;

    .graph-selected-drawer-segment-indication-freq {
      font-size: 0.73rem;
      flex: 1;
      max-width: 5.26rem;
      text-align: left;
    }
    .graph-segment-duty-cycle-slider {
      width: 3.68rem;
      margin-right: 0.2rem;
      margin-left: 0.2rem;
    }

    .duty-cycle-percentage-bar,
    .RF-percentage-bar {
      display: flex;
      flex-direction: row;
      align-items: center;

      .graph-segment-duty-cycle-value,
      .graph-segment-RF-value {
        font-size: 0.94rem;
        font-weight: 600;
        width: 2.5rem;
        text-align: left;
        span {
          font-size: 0.63rem;
          font-weight: 400;
        }
      }
      .force-rf-icon,
      .duty-cycle-icon {
        margin-right: 0.2rem;
      }
    }
  }
  .graph-selected-drawer-segment-indication-freq {
    white-space: nowrap;
    padding: 0 0.5rem;
    font-size: 0.7rem;
    flex: 1;
    min-width: 5.2rem;
    max-width: 5.2rem;
    text-align: center;
  }
}
