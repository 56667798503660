@import "Utils/colors";

.loader {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: $default-black;
  opacity: 0.6;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.history-graph-loader {
  margin-left: 1rem;
  opacity: 1;
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
}
