@mixin custom-scrollbar {
  --scrollbar-width: 6px;
  --scrollbar-thumb-color: rgba(0, 0, 0, 0.12);
  --scrollbar-border-radius: 4px;

  scrollbar-width: var(--scrollbar-width);
  scrollbar-color: var(--scrollbar-thumb-color) transparent;

  &::-webkit-scrollbar {
    width: var(--scrollbar-width);
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
    border-radius: var(--scrollbar-border-radius);
    border: 3px solid transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-color);
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: var(--scrollbar-thumb-color);
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background-color: var(--scrollbar-thumb-color);
  }

  &::-webkit-scrollbar-thumb:window-inactive:hover {
    background-color: var(--scrollbar-thumb-color);
  }

  &::-webkit-scrollbar-thumb:window-inactive:active {
    background-color: var(--scrollbar-thumb-color);
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  &::-webkit-resizer {
    background-color: var(--scrollbar-thumb-color);
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-button:start:decrement,
  &::-webkit-scrollbar-button:end:increment {
    display: block;
    height: 0;
    background-color: transparent;
  }
}