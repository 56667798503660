@import "Utils/colors";

.drawers-contactors-buttons-wrapper {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $default-border-color;
  border-radius: 0.2rem;
  padding: 0.8rem;

  &.contactor-on {
    border: 1px solid $contactor-on-color;
  }

  .contactor-buttons-mu-data-summary {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .mu-data-summary {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      color: $default-text-color;

      .mu-data-summary-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        padding: 0 0.4rem;

        .mu-data-summary-item-value {
          font-size: 0.94rem;
          font-weight: 800;
        }

        .mu-data-summary-item-unit {
          font-size: 0.63rem;
          font-weight: 400;
        }
      }
    }
  }

  .project-device-setup-section-header {
    .switch-label {
      position: relative;

      .label {
        position: absolute;
        font-size: xx-small;
        top: 0.5rem;
        left: 2.6rem;
      }
    }
  }

  //.mu-leds-indication {
  //  position: relative;
  //  display: flex;
  //  flex-direction: column;
  //  align-items: flex-start;
  //  justify-content: flex-start;
  //  font-size: x-small;
  //}

  .contactor-on {
    border: 2px solid $contactor-on-color;
  }

  .segments-drawer-data-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .contactor-on {
      border: 2px solid $contactor-on-color;
    }

    .first-drawer {
      .segments-drawer {
        border: none !important;
      }
    }

    .segments-drawer-data {
      max-width: 100px;
      margin-top: 0.78rem;
      cursor: pointer;

      .segments-drawer {
        border-left: 1px solid $default-border-color;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        padding-top: 0.3rem;
        padding-bottom: 0.42rem;

        &.selected {
          background-color: $default-selected-color;
        }

        &.disabled {
          background-color: #d8d8d8;
          color: $default-white;
        }
      }

      .drawer-force-indication-buttons {
        margin: 0px;
        margin-top: 0.63rem;
        margin-bottom: 0;
        align-items: flex-start;
      }
    }

    .switch-label {
      position: relative;
      left: -0.63rem;
      top: 0.57rem;
    }
  }
}
