@import "Utils/_colors.scss";

.switch-button {
  background: rgba(255, 255, 255, 0.56);
  border-radius: 0.2rem;
  border: 1px solid $default-border-color;
  overflow: hidden;
  min-width: 6rem;
  height: 1.6rem;
  text-align: center;
  font-size: 0.6rem;
  letter-spacing: normal;
  position: relative;
  color: $default-text-color;

  &:before {
    content: "HP";
    border-radius: 0.2rem;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    text-align: center;
    height: 100%;
    z-index: 2;
  }

  &.checked {
    &:before {
      color: #fff;
      background-color: $contactor-on-color;
      height: calc(100% - 4px);
      border: 2px solid $default-button-color;
      border-left: 0;
    }
  }

  &.unchecked {
    .switch-button-label {
      &:before {
        background-color: $default-light-white;
        border-radius: 0.2rem;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  &-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    margin: 0;

    .checked {
      &:checked + .switch-button-label:before {
        transform: translateX(3.42rem);
        transition: transform 300ms linear;
        background-color: $contactor-on-color;
      }
    }

    & + .switch-button-label {
      position: relative;
      width: 50%;
      height: 100%;
      display: block;
      user-select: none;
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: "";
        background: $default-white;
        height: calc(100% - 4px);
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transform: translateX(0);
        transition: transform 300ms;
        border: 2px solid $default-white;
        border-right: 0;
      }

      .switch-button-label-span {
        position: relative;
        top: 0;
      }
    }
  }
}
