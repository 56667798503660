@import "Utils/_colors.scss";

.app-time-input-wrapper {
  cursor: pointer;
  margin-right: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  .app-time-input {
    cursor: pointer;
    border: 1px solid $default-border-color;
    box-sizing: border-box;
    border-radius: 4px;
    height: 1.9rem;
    width: 6rem;
    background: $default-button-color;
    padding-left: 0.5rem;
    font-size: 0.7rem;
    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
      min-width: fit-content;
      margin: 0.2rem;
    }
  }
  .is-updating {
    background: $default-selected-color;
    color: #0000ff;
  }
  label {
    margin-right: 0.42rem;
  }
}
