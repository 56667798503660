.recharts-tooltip-wrapper {
  z-index: 50;
}

.custom-tooltip {
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid grey;
  -webkit-box-shadow: 2px 3px 10px 1px rgba(0, 0, 0, 0.54);
  box-shadow: 2px 3px 10px 1px rgba(0, 0, 0, 0.54);
  margin-top: -20%;
  position: relative;
  width: 15rem !important;
  font-size: 0.7rem;

  p {
    margin: 0;
    padding: 0;
  }

  .tooltip-header {
    display: flex;
    padding: 0.5rem 0.5rem;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;

    .unit-type {
      font-weight: bold;
      margin-left: 0.2rem;
    }
  }

  .tooltip-device-info {
    margin: 0.2rem 0 0.4rem 0;
    width: 100%;
  }

  .tooltip-device-data {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 auto;
    padding: 0.5rem 0.5rem;
    font-size: 0.7rem;

    .name-container {
      flex: 0 1 70%;
      display: flex;
      flex-direction: row;
      color: #bfc1c2;
      justify-content: space-between;
      text-align: left;
      font-size: 0.7rem;
    }

    .color-index {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 2px;
      margin: 0 0.7rem 0 0.2rem;
      flex: none;
    }

    .data-value {
      align-self: flex-end;
      white-space: nowrap;
    }
  }

  .primary-unit {
    border-bottom: 1px dashed lightgray;
    padding: 0.3rem 0.5rem 0.5rem;
    margin-bottom: 0.3rem;

    .color-index {
      background-color: #00ff00;
    }
  }
}
