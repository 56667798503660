@import "Utils/colors";

.control-unit {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .control-unit-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .segment-driver-status-indication {
      margin-left: 0.5rem;
    }

    .vu-data-summary {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-right: 0.84rem;
      color: $default-text-color;
      font-weight: bold;

      .vu-data-summary-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        width: 4.2rem;

        .vu-data-summary-item-value {
          font-size: 0.94rem;
        }

        .vu-data-summary-item-unit {
          font-size: 0.63rem;
        }
      }
    }
  }

  .control-unit-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.78rem 0;

    .vu-resonance-freq {
      margin-right: 1rem;
    }

    .cpu-cuhs-data > .data-summary.left-border {
      margin-left: 1px;
    }

    .cpu-cuhs-data > .data-summary.no-left-border {
      margin-left: 1px;
      width: 4.2rem;
    }

    .control-unit-data {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-left: 1px;

      .control-unit-fan {
        display: flex;
        flex-direction: row;
        border-left: 1px solid $default-border-color;
        padding: 0px 0px 0px 8px;
        gap: 0.26rem;
        margin-left: 0.5rem;

        img {
          max-width: 0.84rem;
        }

        .indication-button-text {
          padding: 0px !important;
        }
      }

      .dc-sampler-data {
        justify-content: center;
      }
    }
  }
}
