@import "Utils/_colors";

.search-box {
  * {
    font-size: 0.73rem;
    border: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  :root {
    font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1920 - 320));
  }

  ::placeholder {
    font-family: $primary-font-family;
    color: $default-header-text-color;
  }

  input {
    color: #f4f4f4 !important;
  }

  .search-icon {
    img {
      max-width: 0.89rem;
    }
  }

  .search-bar {
    display: flex;
    position: relative;
  }

  .search-bar input {
    transition: all 0.25s ease-out;
  }

  .search-bar input {
    width: 3rem;
    height: 2.1rem;
    font-family: $primary-font-family;
    color: $default-header-text-color;
  }

  .search-bar,
  .search-bar input:focus {
    &.closed {
      left: -50%;
      width: min-content;
    }
    &.opened {
      bottom: 2px;
      top: 1px;
    }
  }

  .search-bar input:focus,
  .search-bar input:not(:focus) + .search-btn:focus {
    outline: transparent;
  }

  .search-bar {
    margin: auto;
    justify-content: center;
    max-width: 60rem;
  }

  .search-bar input {
    background: transparent;
    box-shadow: 0 0 0 0.4rem #171717 inset;
    padding-left: 0.26rem;
    transform: translate(0.5rem, 0.5rem) scale(0.5);
    transform-origin: 100% 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
  }

  .search-bar input::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  .search-bar {
    &.opened {
      input {
        box-shadow: none;
        transform: scale(1);
        width: 3.6rem;
        height: auto;
      }
    }
  }
}
