@import "Utils/colors";

.forces-buttons-contactor-warning {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;

  &.contactor-on {
    border: 1px solid $contactor-on-color;
    padding: 3px;
    border-radius: 4px;
  }
}
