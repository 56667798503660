@import "Utils/colors";

.segment-square {
  cursor: pointer;
  margin-top: 0.2rem;
  background-color: $default-white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.63rem;
  width: 1.7rem;
  height: 1.2rem;
  border-radius: 0.84rem;
  display: flex;
  flex-direction: row;
  padding: 0 0.4rem;

  .image-wrapper {
    width: 0.8rem;
    flex: none;

    img {
      width: 100%;
      display: block;
    }
  }

  .segment-number {
    margin-left: 0.31rem;
    min-width: fit-content;
  }
}
