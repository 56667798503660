.recharts-brush > rect {
  stroke: none;
}

.recharts-brush-texts {
  font-family: Urbanist, sans-serif;
  font-size: 12px;
}

.custom-brush-handle-right {
  transform: translateX(15px, 3px);
}

.custom-brush-handle-right g {
  transform: rotate(180deg);
  transform-origin: 20px 20px;
  -webkit-transform-origin: 25px 21.5px;
}

.recharts-text[text-anchor="end"] {
  transform: translateX(-15px);
}

.recharts-text[text-anchor="start"] {
  transform: translateX(15px);
}

.recharts-text {
  pointer-events: none;
}
