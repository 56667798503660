.parking-spot-control-wrapper {
  margin-bottom: 1rem;
  margin-top: 1rem;
  .parking-row {
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .parking-header {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}
