@import "Utils/colors";

.table {
  border-spacing: 0;
  border: 1px solid $default-border-color;
  background-color: $default-white;
  color: #616c81;
  font-size: 0.6rem;
  margin-top: 0.8rem;

  tr {
    &:last-child {
      td {
        border-bottom: 0;
      }
    }

    td {
      border-right: 1px solid $default-border-color;

      &:last-child {
        border-right: 0;
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    :last-child {
      border-right: 0;
    }
  }

  th {
    border-bottom: 1px solid $default-border-color;
    text-align: left;
  }

  thead {
    tr:nth-child(1) {
      background-color: $default-white;
    }
  }

  tbody {
    font-weight: bolder;

    tr:nth-child(odd) {
      background-color: $default-white;
    }

    tr:nth-child(even) {
      background-color: $default-white;
    }
  }
}
