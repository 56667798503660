.menu {
  width: 250px;
  height: 80px;
  display: flex;
  flex-direction: column;
  background: #fff;
  position: relative;
  text-align: center;
  margin: auto;

  .closeIcon {
    position: absolute;
    right: -5%;
    top: -11%;
    font-size: 20px;
    background: #fff;
    border-radius: 50%;
    opacity: 0.88;
    cursor: pointer;
  }
  .menu-item {
    cursor: pointer;
    height: 28px;
    border-bottom: 1px solid rgb(187, 187, 187);
    text-align: center;
  }
  .menu-item:hover {
    color: #2980b9;
    background-color: rgba(187, 187, 187, 0.5);
  }
}
.closeIcon {
  position: absolute;
  right: -3%;
  top: -4%;
  font-size: 20px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  border: black 1px solid;
}
.actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin: 3px auto;

  button {
    cursor: pointer;
    font-size: 20px;
  }
}

.btn {
  white-space: nowrap;
  display: inline-block;
  border-radius: 5px;
  font-size: 16px;
  color: black;

  &:hover {
    color: red;
    border: red 1px solid;
  }
}
.confirmDelete {
  padding: 3px 5px;
}
