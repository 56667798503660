@import "Utils/colors";

.project-device-setup-selected-drawer {
  font-size: small;
  padding-right: 1%;
  padding-left: 1%;
  flex: 0 1 28%;
  text-align: left;

  .project-device-setup-section-header {
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.4rem;
  }

  .project-device-setup-selected-drawer-segments-fans {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-height: fit-content;
    min-width: fit-content;

    .project-device-setup-selected-drawer-fans {
      display: flex;
      flex-direction: column;
      min-width: fit-content;
    }
    .project-device-setup-selected-drawer-segments {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      .rack-segment-wrapper {
        width: 19.3rem;
      }
      .separator-line {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
        width: 117%;
        //margin-left: -17%;
        img {
          max-width: 100%;
        }
      }

      .selected-drawer-segment {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-left: 0.36rem;
        padding-left: 0.15rem;
        height: 1.7rem;
        border-radius: 1.5rem;
        padding: 0.15rem 0.5rem;
        gap: 0.4rem;
        cursor: pointer;

        &.selected {
          background-color: $default-selected-color;
        }

        .selected-drawer-segment-indication-freq {
          font-style: normal;
          min-width: 3.8rem;
          color: $default-text-color;
          font-size: 0.7rem;
          white-space: nowrap;
        }
        .selected-drawer-segment-duty-cycle-wrapper {
          min-width: 1.7rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .selected-drawer-segment-duty-cycle-value {
        max-width: 1.8rem;
        font-size: 0.9rem;
        font-weight: 600;
        font-style: normal;
        span {
          font-size: 0.63rem;
          font-weight: normal;
          margin-left: 0.2rem;
        }
      }
      .segment-square {
        margin: 0px;
      }
    }
  }
  // .app-button {
  //   position: relative;
  //   top: 2.63rem;
  // }
}
