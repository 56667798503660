.card-details{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding: 0.78rem;
  padding-bottom: 0.4rem;

  .card-titles{
    display: flex;
    margin-bottom: 0.78rem;
    font-size: 0.8rem;
    .card-titles-main {
      font-weight: 700;
      margin-right: 0.5rem;
    }
    .card-titles-sub{
      color: darkred;
    }
  }

  .indication-icons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    background-color: #f8f8f8;
    font-size: 0.8rem;
    .image-wrapper {
      width: 1rem;
      img {
        max-width: 100%;
      }  
    }
    > div {
      padding: 0.5rem 0.78rem;
      position: relative;
    }
  }
}