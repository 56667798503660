@import "Utils/colors";

// set variable for body padding
$analytics-body-padding-top: 56px;
$analytics-body-padding-x: 52px;
$page-header-height: 60px;
@mixin default-font {
  font-family: "Urbanist", sans-serif;
}
.project-analytics-page,
.project-analytics-page * {
  box-sizing: border-box;
  @include default-font;
}

.project-analytics-page {
  width: 100%;
  height: 100%;
}

.project-analytics-body {
  padding: $analytics-body-padding-top $analytics-body-padding-x 0
    $analytics-body-padding-x;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: calc(100vh - #{$analytics-body-padding-top});
}

.analytics-tool-container {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  height: 40px;
  gap: 16px;
}

.analytics-tool-wrapper {
  color: $default-text-color;
  width: 100%;
  height: 100%;

  .zoom-info-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 5px 0 0 0;
    padding-bottom: 0;
  }
  .zoom-table-update {
    margin-top: 1%;
  }
  .zoom-info {
    display: flex;
    width: 40%;
    align-items: center;
  }
  .zoom-reset-btn {
    margin-right: 1%;
  }

  .analytics-tool-sections-wrapper {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    // position: relative;

    .selected-devices {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 1rem 0;
      padding: 1rem 0;
      border-bottom: 2px solid $default-border-color;

      .device_item {
        font-size: 12px;
        margin: 5px 0;
      }
    }

    .selection-wrapper {
      display: flex;
      align-items: center;
      height: 3.3rem;
      color: $white-text-color;
      width: 100%;
      border-bottom: 2px solid $default-border-color;
      background-color: $analytics-top-menu;
      button,
      .span-skip-btn {
        background-color: transparent;
        border: none;
        color: $white-text-color;
      }
    }
  }
  .analytics-graph {
    position: relative;
    padding-bottom: 5%;

    .custom-tooltip {
      width: 220%;
    }
  }
}
