@import "Utils/colors";

.search-drop-down {
  border: 1px solid $default-black;
  position: fixed;
  z-index: 2;
  background-color: $default-white;
  top: 3.6rem;
  width: 20vw;
  color: $default-button-color;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 50vh;

  .unit-search-result {
    display: flex;
    flex-direction: column;
    .search-result-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid $default-black;
      border-top: 1px solid $default-black;
      padding: 5px;
      background-color: #b3fff0;
      color: $default-black;
      text-overflow: ellipsis;
    }
    .filtered-search-item {
      border-bottom: 1px solid;
      padding: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding-left: 8px;
      inline-size: 100%;

      .filtered-search-dropdown {
        padding-top: 0%;
        width: 60%;
      }
      .link-dropdown {
        padding-left: 10%;
        padding-top: 2%;
        text-decoration: none;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        color: $default-light-blue;
        font-size: 10px;
        overflow-wrap: break-word;
      }
      .identity-dropdown {
        padding-left: 10%;
        padding-top: 4%;
        text-decoration: none;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        color: $default-light-blue;
        font-size: 9px;
      }
      mark {
        background-color: $default-hover-color;
      }
    }
  }
}
.search-drop-down::-webkit-scrollbar {
  width: 10px;
}

.search-drop-down::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 100px;
}

.search-drop-down::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 6px solid $branding-gray;
  border-left: 0;
  border-right: 0;
  background-color: $branding-gray;
}
.no-result-message {
  border: 1px solid $default-black;
  position: fixed;
  z-index: 2;
  background-color: $default-white;
  top: 3.6rem;
  width: 19vw;
  color: $default-black;
}
