@import "Utils/colors";

.skip-span-btn-wrapper{
    display:flex;
    flex-direction: row;
    position: relative;
    left: 0.5%;
    
    .span-skip-btn{
      background-color: $default-white;
      pointer-events: all !important;
      position: relative;
      cursor: pointer;
      border: 1px solid $default-subtitle-color;
      border-radius: 0.2rem;
      height: 1.6rem;
      width: 1.6rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;     
      
      // create gap from first child
      &:nth-child(2){
        position: relative;
        left: 10%;
      }

      &.disabled {
        border-color: $default-light-white;
        background-color: $default-light-white;
        color: $default-gray;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }