@import "Utils/colors";

.project-control-panel-wrapper {
  display: block;
  width: 100%;
  padding-top: 0.5rem;
  text-align: left;

  .mu-control-panel-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 0.7rem;
    color: $default-text-color;
    margin: 0.5rem 0 0;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $default-border-color;
    align-content: stretch;

    .numeric-input-row {
      padding: 0.2rem 0.3rem;
      display: flex;
      min-width: fit-content;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      label {
        width: 3rem;
        font-size: 0.6rem;
        text-align: left;
      }

      input {
        width: 4.2rem;
      }
    }
  }

  .advanced-resonance-apply {
    width: 7.5rem;
  }

  .is-updating {
    background: $default-selected-color;
    color: #0000ff;
  }
}
