@import "Utils/colors";

.receiver-view {
  .vu-receivers-header-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.57rem;
  }

  .table-receiver-fan {
    display: flex;
    flex-direction: row;
    padding: 0px 0px 0px 0.42rem;
    gap: 0.26rem;

    .indication-button-text {
      padding: 0px !important;
    }
  }

  .vu-receivers-table-current {
    display: flex;
    align-items: center;
    width: 30px;

    .data-summary {
      margin-left: 0px;
    }
  }

  .vu-receivers-table-temperature {
    display: flex;
    align-items: center;
    gap: 0.26rem;
    width: 100px;

    .data-summary .value {
      font-size: 0.84rem;
      min-width: 25px;
    }

    .data-summary {
      margin-left: 0.26rem;
      padding: 0px;
    }
  }
}

.table-receiver-control-button {
  width: 4rem;
}

.table-receiver-control-button,
.vu-receivers-debug-wrapper {
  display: flex;

  .switch-button {
    margin: 0px;
    font-size: 0.57rem;

    &:before {
      content: "OFF";
    }

    &.checked {
      .switch-button-label-span {
        color: $default-text-color;
      }

      &:before {
        background-color: $switch-btn-bg;
        height: 1.35rem;
      }
    }

    &.unchecked {
      .switch-button-label-span {
        color: $default-button-color;
      }
    }
  }

  .switch-button.unchecked .switch-button-label:before {
    background-color: $switch-btn-bg;
  }
}

.vu-receivers-debug-wrapper {
  margin: 0.2rem;
  justify-content: center;
  align-items: center;
  position: relative;

  .switch-button {
    margin-left: 0.5rem;
  }

  .switch-button:before {
    content: "DEBUG" !important;
  }

  .segment-driver-status-indication {
    justify-content: center;
  }

  .segment-driver-status-tooltip {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;

    .custom-tooltip {
      text-align: left;
      top: 2rem;
      left: 100%;
      width: fit-content !important;
      white-space: nowrap;
      padding: 0 0.5rem;
    }
  }
}

.voltage-tracking {
  .switch-button:before {
    content: "VT";
  }
}

.normal-tracking {
  .switch-button:before {
    content: "EV";
  }
}

.debug-mode {
  .switch-button:before {
    content: "REQ ON";
  }
}
