@import "Utils/colors";

.depot-summary-card-section {
  padding-bottom: 1rem;
  height: fit-content;
  border: 1px solid $default-light-white;
  box-shadow: 2px 2px $default-light-white;
  width: 20rem;

  .depot-name {
    background-color: $default-light-white;
    height: 50px;
    line-height: 50px;
    font-size: 1rem;
    font-weight: bolder;
  }
  .charging-stations-title,
  .charging-vehicles-title,
  .total-energy-title {
    width: 45%;
    line-height: 2.25rem;
    font-weight: bold;
    text-align: left;
  }
  .charging-stations-amount,
  .charging-vehicles-amount,
  .depot-total-energy {
    width: 45%;
    line-height: 3rem;
    font-weight: bold;
    text-align: right;
    font-size: 1.5rem;
  }

  .separator-line {
    width: 90%;
    height: 1%;
    margin: 5% auto;
    img {
      max-width: 100%;
    }
  }

  .charging-vehicles,
  .charging-station {
    width: 100%;
    margin: 0 auto;
    text-align: left; // center
  }

  .charging-station,
  .charging-vehicles,
  .terminal-energy-title {
    font-size: 1rem;
    padding-left: 1rem;
    display: flex;
    flex-direction: row;
    width: 18rem;
  }

  .charging-depot-table {
    background-color: $side-navigation-background-color;
    margin-left: 5%;
    border-collapse: collapse;
    font-size: 1rem;
    border-color: transparent;
    width: 90%;
  }
}
