.segment-driver-status-indication {
  width: 5.2rem;
  height: auto;
  border-radius: 1.57rem;
  padding: 0.2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.7rem;
  grid-gap: 0.2rem;
  gap: 0.2rem;
  img {
    max-width: 1rem;
  }
}
