@import "Utils/_colors";

.dropzone {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .uploadContainer {
    position: relative;
    display: flex;
    border: 1px black dotted;
    flex-direction: column;
    text-align: center;
    width: 120;
    height: 120;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 10px;
  }
  .error {
    margin-top: 10px;
    color: $contactor-on-color;
  }

  .has-error {
    border: 1px dotted $contactor-on-color;
  }

  .accept {
    border-color: #107c10 !important;
  }

  .reject {
    border-color: #d83b01 !important;
  }

  .svg {
    position: absolute;
    right: 0 !important;
    top: 0 !important;
  }
}
