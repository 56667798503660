@import "Utils/_colors.scss";

.project-view-wrapper {
  font-family: Inter; //todo: check if should be removed
  color: $default-text-color;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;

  .rc-slider-disabled {
    background-color: #fff;
    opacity: 0.5;
  }
  .project-view {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    overflow: hidden;
    background-color: #fff;

    .project-device-header-i {
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 0.6rem;
      img {
        max-width: 1rem;
      }
    }

    .drawer-force-indication-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0.2rem;
      position: relative;
    }

    .indication-buttons {
      display: flex;
      padding-left: 0.5rem;
    }

    .section-name {
      color: $default-text-color;
      font-size: 0.6rem;
      padding: 1.2rem 0.8rem;
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      background-color: $side-navigation-background-color;
      z-index: 2;
      span {
        display: block;
        margin-right: 0.5rem;
        background-repeat: no-repeat;
        background-size: contain;
        &.infrastructure-icon {
          width: 0.85rem;
          height: 0.9rem;
          background-image: url("../../Utils/Images/ProjectMaintanance/infrastructure-icon.svg");
        }
        &.vehicles-icon {
          width: 0.73rem;
          height: 0.85rem;
          background-image: url("../../Utils/Images/SideBarIcons/vehicles-icon.svg");
        }
      }
    }

    .charging-type-wrapper,
    .vehicle-type-wrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding-bottom: 0.5rem;

      .charging-type-title,
      .vehicle-type-title {
        padding: 0.6rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        img {
          max-width: 0.5rem;
          max-height: 0.55rem;
        }

        .charging-type-title-name,
        .vehicle-type-title-name {
          margin-left: 0.3rem;
          font-weight: 300;
        }
      }

      .road-wrapper {
        padding-left: 0.8rem;
        .road-title {
          padding: 0.5rem 0.68rem;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          border-left: 1px solid $default-selected-color;
          img {
            width: 0.5rem;
          }

          .road-title-name {
            margin-left: 0.3rem;
          }
        }
      }

      .mu-list-wrapper,
      .vu-list-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        .mu-list-item,
        .vu-list-item {
          color: #5596ca;
          background-color: $side-navigation-background-color;
          height: 1.2rem;
          display: flex;
          flex-direction: row;
          padding: 0.2rem 1rem;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: left;
          align-items: center;
          justify-content: space-between;
          .list-item-name {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.charging {
              background-color: #ffd600;
            }
          }

          .list-item-icons {
            display: flex;
            align-items: center;
            margin-left: 0.2rem;
          }

          .connection-status {
            &.connected {
            }
            &.disconnected {
              width: 0.42rem;
              height: 0.42rem;
              background-color: #f81728;
              border-radius: 0.42rem;
            }
          }

          .contactor-on {
            display: inline-block;
            padding: 0.2rem 0.3rem;
            border-radius: 0.2rem;
            color: #fff;
            background-color: #f81728;
            font-size: 0.5rem;
            margin-left: 0.2rem;
          }

          .image-wrapper {
            &:last-child {
              img {
                margin-right: -0.2rem;
              }
            }
          }

          img {
            width: 0.8rem;
            margin: 0 0.2rem;
          }

          &.disabled {
            pointer-events: unset;
            border-left: 1px solid $default-selected-color;
          }

          &.closed {
            width: 40%;
            .list-item-name {
              font-size: 0.57rem;
              font-weight: 700;
            }
          }

          &.selected {
            height: 1.2rem;
            background: $default-selected-color;
            // box-shadow: inset 1px 0px 0px #001133;
            border-left: 1px solid $default-text-color;
          }

          .voltage-now {
            background-color: #f6e58d;
          }

          &.connected {
            color: $default-text-color;
          }

          &.disconnected {
            color: $default-tab-text-color;
          }
        }

        .control-panel-icon {
          &.selected {
            background-color: #f6e58d;
          }
        }
      }
    }

    .fan-button {
      height: 1.8rem;
      width: 2.3rem;
      margin: 0.1rem;

      &.fan-selected {
        background-color: #f6e58d;
      }
      .fan-image {
        &.fan-active {
          background-color: #f6e58d;
        }
      }
    }

    @-moz-keyframes spin {
      100% {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    .pane-collapsed-side-bar {
      width: 15% !important;
    }

    .arrow-collapse {
      button {
        background-color: #f7f8ff;
        border: none;
        outline: none;
      }
    }

    .project-view-side-bar {
      font-size: 0.7rem;
      font-weight: 400;
      color: $default-text-color;
      width: 12.8rem;
      flex: none;
      height: calc(100% - 3.6rem);
      .side-bar {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        min-height: 2rem;

        &.control-panel-open {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        button {
          z-index: 2;
        }

        .switch-icons-project-side-list {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          &.pressed {
            .right-pointing-icons {
              margin-left: 0.26rem;
            }
            .control-panel-icon {
              background-color: #f6e58d;
            }
            .control-panel-mu-name {
              margin-left: 0.26rem;
              font-weight: bold;
            }
          }
        }
      }

      .project-view-side-bar-lists {
        width: 100%;
        min-width: 3%;
        height: 100%;
        .project-unit-list-view {
          height: 100%;
          width: 100%;
          background-color: $side-navigation-background-color;
          cursor: pointer;
          .project-unit-list-view-sections {
            display: flex;
            flex-direction: column;
            height: 100%;
          }
        }

        .section-wrapper {
          border-bottom: 1px solid $default-subtitle-color;
          position: relative;
          overflow-y: auto;
          // flex: 1 1 100%;
          height: 100%;
        }

        .project-control-panel-wrapper {
          height: 100%;
          width: 100%;
        }

        .unit-line {
          width: 0.6rem;
          height: 0.1rem;
          background-color: #000;
        }

        .project-view-side-bar-mu-list {
          margin-top: 0.26rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          button {
            height: 1.84rem;
            width: 2.3rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          }
        }

        .project-view-side-bar-vu-list {
          margin-top: 0.26rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          .vu-vehicles {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .vu-list-item {
              border: 1px solid black;
              background-color: #fff;
              height: 1rem;
              margin: 0.2rem;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding: 0.26rem;
              width: 85%;

              &.closed {
                width: 40%;

                .list-item-name {
                  font-size: 0.5rem;
                  font-weight: 700;
                }
              }

              &.selected {
                border: 0.15rem solid #f6e58d;
                background-color: #5ddceb;
              }
            }
          }
        }
      }
    }

    .project-view-main {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(100% - 3.6rem);
      overflow-y: auto;
      overflow-x: hidden;
      background-color: #fff;
      position: relative;
      .project-tabs {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        border-bottom: 1px solid $default-border-color;
        width: 100%;
        min-height: 3.36rem;
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 5;

        .project-tab {
          cursor: pointer;
          width: 5.2rem; // from login
          min-width: fit-content;
          color: $default-tab-text-color;
          display: flex;
          font-weight: normal;
          font-size: 0.6rem;
          align-items: center;
          align-content: center;
          justify-content: center;

          margin-left: 1.2rem;

          .project-tab-name {
            min-width: fit-content;
          }

          img {
            margin-right: 4%;
            max-width: 1.2rem; //from login
          }

          &.selected {
            border-bottom: 1px solid $default-text-color;
            color: $default-text-color;
            font-weight: bold;
          }
        }
      }

      .project-device-header {
        width: 100%;

        .project-device-header-name-i {
          padding: 0.68rem;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          .project-device-header-name {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .project-device-setup {
        width: 100%;
        position: relative;

        .project-device-setup-sections {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          width: 100%;

          .setup-section {
            border-right: 1px solid $default-border-color;
            padding: 0.7rem;
            .section-header {
              margin-bottom: 0.7rem;
            }
          }

          .section-header.title-indication-buttons {
            justify-content: space-between;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
          }
          .project-device-setup-drawers {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            flex: 0 1 30%;

            .project-device-setup-section-drawers {
              width: 100%;
            }

            .section-header {
              position: relative;

              .header-title {
                display: flex;
                align-items: center;
              }
              .header-buttons {
                display: flex;
              }
            }
          }

          .SD-header {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .project-device-setup-section-header {
              width: 10.5rem !important;
            }
          }
          .project-device-setup-selected-segment {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: small;
            flex: 1 1 40%;
            .project-device-setup-section-header-title {
              min-width: fit-content !important;
              margin-right: 5px;
            }
            .project-device-setup-selected-segment-buttons-SD-top {
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;
              .pressed {
                // background-color: rgba(204, 221, 255, 0.5);
              }
              .pressed:hover {
                // background-color: $default-hover-color;
              }
            }
            .segment-driver-status-indication {
              min-width: fit-content;
              width: 5.7rem;
              height: auto;
              border-radius: 1.57rem;
              padding: 0.2rem;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              img {
                max-width: 1rem;
              }

              .segment-driver-status-indication-text {
                margin-left: 0.2rem;
                font-size: 0.63rem;
              }
            }

            .project-device-setup-section-header {
              justify-content: space-between;
              width: 98%;
              margin-bottom: 1rem;
            }

            .setup-device-graph {
              width: 100%;
              position: relative;
              .recharts-responsive-container {
                position: relative !important;
                width: 110% !important;
                height: 320px !important;
              }
            }

            .segment-force-on-indicator-wrapper {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;

              .segment-force-on-indicator {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 1.57rem;
                border: 1px solid;
                width: 8.1rem;
                border-radius: 0.26rem;
                margin-top: 0.42rem;
                background-color: #fffe02;
              }
            }
          }
        }
      }
      .pane-collapsed {
        height: 0px !important;
        min-height: 0%;
        min-width: 9%;
      }

      .pane-not-collapsed {
        min-height: 30%;
        height: 30%;
      }

      .project-view-main-mus-vus {
        flex: 2;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .pane-collapsed-mu {
          width: 7% !important;
          height: 100% !important;
        }
        .pane-collapsed-vu {
          height: 100% !important;
          width: 7% !important;
        }
        .pane-not-collapsed-mu {
          width: 100%;
          height: 100%;
        }

        .project-view-main-mus {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          width: 99.9%;
          border-right: 1px solid;
          height: 100%;

          .mus-drawers-segments {
            flex: 1;
            min-width: 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            width: 100%;
            .segment-fan {
              .segment-fan-line-icon {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                left: 50%;

                .unit-line-vertical {
                  width: 2px;
                  height: 0.63rem;
                  background-color: #000;
                }
              }
            }

            &.closed {
              .control-panel-segment {
                display: none;
              }
              .project-view-main-mus-drawers {
                display: none;
              }
              .project-view-main-mus-selected-tabs {
                position: relative;
                display: flex;
                flex-direction: column;

                .selected-tab {
                  border-left: 1px solid;
                  font-size: 0.57rem;
                  font-weight: bold;
                  &.selected {
                    border-left: 0.15rem solid #f6e58d;
                  }
                }
              }
              .project-view-main-mus-selected-segments {
                border: none;
                width: 97%;
                padding-right: 0.4rem;
                margin: 0.15rem;
                margin-top: 0.5rem;
                .project-view-main-mus-selected-segment {
                  border: 1px solid;
                  margin: 0.26rem;
                  width: 91%;
                  height: 2.6rem;

                  .selected-segment-name {
                    font-size: 0.57rem;
                    font-weight: bold;
                  }
                }
              }
            }

            .project-view-main-mus-selected-tabs {
              width: 97%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              margin-top: 1px;
            }

            .segments-wrapper {
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 100%;

              .segments-drawers {
                display: flex;
                flex-direction: row;
                justify-content: center;
                width: 100%;

                .project-view-main-mus-drawers {
                  top: 0%;
                  bottom: 0;
                  right: 0;
                  position: relative;
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;

                  .drawer-number {
                    position: relative;
                    width: 1.42rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .segment-square-zero-row {
                      padding-right: 0.2rem;
                      position: relative;
                      top: 1px;
                      padding-top: 0.19rem;
                    }
                  }
                }

                .project-view-main-mus-selected-segments {
                  width: 97%;
                  top: 0%;
                  overflow-y: auto;
                  height: 67%;
                }
              }
            }
          }
        }

        .project-view-main-vus {
          min-width: 30%;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          height: 100%;
          width: 100%;

          .project-view-main-vus-selected-tabs {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 94%;
            margin-top: 1px;
            margin-left: 0.78rem;
            margin-right: 0.78rem;

            &.closed {
              position: relative;
              display: flex;
              flex-direction: column;

              .selected-tab {
                border-left: 1px solid;
                font-size: 0.57rem;
                font-weight: bold;
                margin-right: 0.68rem;
                &.selected {
                  border-left: 3px solid #f6e58d;
                }
              }
            }
          }
        }
      }
    }
  }

  .recharts-wrapper {
    width: 100% !important;
    svg {
      width: 100% !important;
      margin-top: 1rem;
      text {
        font-size: 0.8rem;
      }
    }
  }
}
.segment-operational-mode-info {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  .graph-segment-duty-cycle-slider {
    width: 4.7rem;
    padding: 0.1rem 0;
  }

  .duty-cycle-percentage-bar,
  .RF-percentage-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0.3rem;

    .value-icon-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .graph-segment-duty-cycle-value,
    .graph-segment-RF-value {
      font-size: 0.94rem;
      font-weight: 600;
      min-width: 41px;
      text-align: left;

      span {
        font-size: 0.63rem;
        font-weight: 400;
      }
    }
    .force-rf-icon,
    .duty-cycle-icon {
      margin-right: 4px;
      width: 0.84rem;
      img {
        width: 100%;
      }
    }
  }
}

.buttons-wrapper,
.title-wrapper {
  display: flex;
}

.fan-force-all {
  display: flex;
}

.locked-unlock-icon {
  pointer-events: all !important;
  position: relative;
  cursor: pointer;
  border: 1px solid $default-subtitle-color;
  border-radius: 0.2rem;
  height: 1.6rem;
  width: 1.6rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: $default-hover-color;
  }

  img {
    max-width: 0.84rem;
  }

  &.disabled {
    &:hover {
      background-color: #fff;
      cursor: not-allowed;
    }
  }
}

.react-split__sash {
  z-index: 4 !important;
  &--horizontal {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $default-OFF-hover-color;
    &:before,
    &:after {
      content: "";
      width: 16px;
      height: 1px;
      background: $default-text-color;
    }
    &:before {
      margin-bottom: 2px;
    }
    &:hover {
      background-color: $default-hover-color;
    }
  }
}
