.tooltip-wraper {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
  background-color: #f1f1f1e6;
  border-radius: 4px;
  font-size: 12px;
  padding: 4px 8px;
  border: 1px solid #0000001f;
  font-weight: 400;
  font-family: 'Urbanist', sans-serif;
  min-height: 25px;
  text-align: center;

  p {
    margin: 0;
    padding: 0;
  }
}
