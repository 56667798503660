@import "Utils/_colors.scss";

.activity-indicator-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 !important;
  width: 100%;
  margin: 0 0.2rem;
  font-size: 0.7rem;

  .activity-indicator {
    max-width: 1rem !important;
    max-height: 1rem !important;
    border-radius: 50%;
    border: 2px solid $default-white;
  }
}
