.project-device-setup-section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: large;
}

.project-device-setup-section-header-title {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.94rem;
  font-family: Inter;
  font-style: normal;
  margin-right: 0.5rem;
}
