@import "Utils/colors";

.home-dashboard {
  width: 100%;
  height: 100%;
  overflow: scroll;

  // removes scroll bar but keeps scroll functionality
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, Opera*/
  }

  .home-dashboard-main {
    height: calc(100% - 3.6rem);
    position: relative;
    padding: 0 1rem;

    .dashboard_loader {
      position: absolute;
      bottom: 0;
    }

    .home-dashboard-active-tab {
      .dashboard-filter-boxes {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .dashboard-filter-box {
          color: $default-white;
          margin: 1rem;

          .dashboard-filter-box-list {
            padding: 1rem;
            border: 1px solid #ffc0cb;
            max-height: 5.26rem;
            overflow-y: auto;
            margin: 1rem;
          }
        }
      }

      .dot_on_off {
        font-size: 1.15rem;
        height: 0.78rem;
        width: 0.78rem;
        display: inline-block;
        border-radius: 50%;

        &.active {
          background-color: $default-green;
        }

        &.not-active {
          background-color: $default-red;
        }
      }
    }

    .projects-cards {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1rem;
      height: 100%;
      width: 100%;
      justify-content: center;
    }
  }
}
