.segment-setting-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .selected-segment {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.26rem 0;
    align-items: center;

    .rc-slider {
      padding: 0.57rem !important;
      width: 8.26rem;
    }

    .duty-cycle-slider {
      width: 7.1rem;
    }

    .selected-segment-value {
      color: #026fc0;
      margin-left: 0.94rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .resonance-freq {
    justify-content: flex-start !important;
  }

  .segment-slider-data {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .rc-slider {
      left: 0.31rem;

      .rc-slider-handle {
        width: 1.24rem !important;
        height: 1.24rem !important;
        top: calc(50% - 1.24rem / 3) !important;
        margin-top: 0 !important;
      }
    }
  }

  .segment-settings-freq-calibrate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .selected-segment-name {
    width: 3.68rem;
    text-align: start;
    font-size: 0.75rem;
  }

  .segment-settings-forces {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .segment-settings-sliders-checkboxes {
      width: 100%;

      .segment-settings-sliders-forces-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        .set-all-freq {
          margin-top: 0.5rem;
        }

        .forces-buttons-contactor-warning {
          margin-left: auto;
        }
      }

      .segment-settings-force-checkboxes {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.7rem;

        .project-device-setup-section-header {
          width: 10.5rem !important;
        }
      }
    }

    .segment-settings-freq-duty-force {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 0.68rem;
    }

    .segment-slider-data {
      margin-top: 0.5rem;

      .selected-segment {
        padding: 0;
      }
    }

    .segment-settings-force-pwm-rf {
      width: 100%;
      padding: 0.84rem;

      .segment-settings-force-pwm {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0.63rem;
        margin-top: 3.15rem;
        margin-left: 0.5rem;
      }
    }
  }

  .segment-settings-freq-calibrate-phase {
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-top: 0.68rem;
    padding-bottom: 0.68rem;

    .segment-settings-phase {
      padding: 0.26rem 0;

      .app-number-input {
        width: 3.94rem;
        height: 1.9rem !important;
      }
    }

    .app-button {
      height: 1.9rem;
      padding: 0;
      padding: 0 0.5rem;
      white-space: nowrap;
    }

    .set-all-freq {
      width: 3.68rem;
    }
  }

  .graph-radio-buttons-wrapper {
    width: 30%;
    border-right: 1px solid #e0e0e0;
    padding: 0.26rem;

    .graph-radio-buttons {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .graph-radio-button {
        padding-top: 0.78rem;
      }
    }
  }

  .set-all-freq {
    width: 3.68rem;
    height: 1.9rem;
    padding: 0;
  }
}

.project-device-setup-selected-segment-buttons-i {
  display: flex;
}
