.collapsible {
  display: flex;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 0.73rem;
  color: #80AAFF;
  background-color: transparent;
  max-width: fit-content;
  padding:0.26rem;


  .show-icon{
    transform: rotate(180deg);
  }
  img{
    margin-right: 0.26rem;
  }

}

.collapsible-content {
  display: block;
  overflow: hidden;
}


