@import "Utils/colors";

:root {
  --input-border: #8b8a8b;
  --input-focus-h: 245;
  --input-focus-s: 100%;
  --input-focus-l: 42%;
}

.login-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  background: url("../../Utils/Images/backgroundImages/login-road-min.jpg") no-repeat center
    center fixed;
  justify-content: center;
  background-size: cover;
}

.login-inner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: relative;

  background-color: $default-white;
  border-radius: 11px;
  padding: 45px;

  img {
    height: 90px;
    margin-bottom: 30px;
  }
  @keyframes error-shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(2px);
    }
    50% {
      transform: translateX(-2px);
    }
    75% {
      transform: translateX(2px);
    }
    100% {
      transform: translateX(0);
    }
  }
  .error-border {
    border-color: $default-red;
    border-radius: 3px;
    animation: error-shake 0.3s;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4);
  }
  .login-attempt-failed {
    border-radius: 3px;
    animation: error-shake 0.3s;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4);
  }
  .show-error-message {
    color: $default-red;
    font-size: 11px;
    width: 200px;
  }

  .local-account-login-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .login-form-wrapper {
    background-color: $default-white;
    border-radius: 6px;
    color: $default-text-color;
    width: 400px;
    height: 200px;

    .login-form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .login-form-field {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 10px;

      label {
        flex: 1;
      }

      input {
        margin-top: 10px;
        flex: 1;
        width: 200px;
        height: 70px;
        font-size: 16px;
        font-family: inherit;
        padding: 0.25px 0.5px;
        background-color: $default-white;
        border: 2px solid var(--input-border);
        border-radius: 2px;
        padding: 10px;
      }
    }
  }

  .lost-password {
    padding-top: 10px;
    color: $default-white;
  }

  .electreon-submit-login {
    margin-top: 30px;
    width: 200px;
    height: 45px;
    border-radius: 23px;
    background: linear-gradient(to right bottom, #04d4c5, #0498c2);
    color: $default-white;
    font-size: 16px;
  }

  button {
    width: 200px;
    height: 70px;
    background: linear-gradient(to right bottom, #04ccbe, #04abdb);
    border-style: none;
    color: $default-button-color;
    font-size: 23px;
    letter-spacing: 3px;
    font-family: "Lato";
    font-weight: 600;
    outline: none;
    cursor: pointer;
    position: relative;
    padding: 0px;
    overflow: hidden;
    transition: all 0.5s;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  }

  button span {
    position: absolute;
    display: block;
  }

  button span:nth-child(1) {
    height: 3px;
    width: 200px;
    top: 0px;
    left: -200px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), $login-bg-gradient);
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
    animation: span1 2s linear infinite;
    animation-delay: 1s;
  }

  @keyframes span1 {
    0% {
      left: -200px;
    }
    100% {
      left: 200px;
    }
  }

  button span:nth-child(2) {
    height: 70px;
    width: 3px;
    top: -70px;
    right: 0px;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      $login-bg-gradient
    );
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
    animation: span2 2s linear infinite;
    animation-delay: 2s;
  }

  @keyframes span2 {
    0% {
      top: -70px;
    }
    100% {
      top: 70px;
    }
  }

  button span:nth-child(3) {
    height: 3px;
    width: 200px;
    right: -200px;
    bottom: 0px;
    background: linear-gradient(to left, rgba(0, 0, 0, 0), $login-bg-gradient);
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
    animation: span3 2s linear infinite;
    animation-delay: 3s;
  }

  @keyframes span3 {
    0% {
      right: -200px;
    }
    100% {
      right: 200px;
    }
  }

  button span:nth-child(4) {
    height: 70px;
    width: 3px;
    bottom: -70px;
    left: 0px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0), $login-bg-gradient);
    border-top-right-radius: 1px;
    border-top-left-radius: 1px;
    animation: span4 2s linear infinite;
    animation-delay: 4s;
  }

  @keyframes span4 {
    0% {
      bottom: -70px;
    }
    100% {
      bottom: 70px;
    }
  }

  button:hover {
    transition: all 0.5s;
    transform: rotate(-3deg) scale(1.1);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4);
  }

  button:hover span {
    animation-play-state: paused;
  }
}
